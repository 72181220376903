module.exports = [
  {
    logo: `${process.env.PUBLIC_URL}/assets/images/logos/people_strong.svg`,
    linkUrl: "https://ampersand.uat.peoplestrong.com/",
    height: '42px',
  },
  {
    logo: `${process.env.PUBLIC_URL}/assets/images/logos/oracle.svg`,
    linkUrl: "https://login-ewzj-test-saasfaprod1.fa.ocs.oraclecloud.com/",
    height: '30px',
  },
  {
    text: "HRMS Onboarding",
    logo: `${process.env.PUBLIC_URL}/assets/images/logos/HH-icon.svg`,
    linkUrl: "https://hrmsonboarding-uat.ampersandgroup.in",
    height: '55px',
  },
  {
    text: "Hubbleorion ERP",
    logo: `${process.env.PUBLIC_URL}/assets/images/logos/HH-icon.svg`,
    linkUrl: "https://hubbleorion-erp.hubblehox.com/",
    height: '55px',
  },
  {
    logo: `${process.env.PUBLIC_URL}/assets/images/logos/hubble_star.svg`,
    linkUrl: "https://hubblestar-uat-admin.hubblehox.ai/#/vgos-internal",
    height: '42px',
  },
  // {
  //   logo: `${process.env.PUBLIC_URL}/assets/images/logos/student_kare.svg`,
  //   linkUrl: "/",
  // },
  // {
  //   logo: `${process.env.PUBLIC_URL}/assets/images/logos/elevate.svg`,
  //   linkUrl: "/",
  // },
  {
    text: "LMS",
    // logo: `${process.env.PUBLIC_URL}/assets/images/logos/lms.svg`,
    linkUrl: "https://lms-frontend-ofsyw7livq-uc.a.run.app/",
    height: '42px',
  },
  {
    text: "Non Academic Survey",
    // logo: `${process.env.PUBLIC_URL}/assets/images/logos/non_academic_survey.svg`,
    linkUrl: "https://sara-uat.hubblehox.ai",
    height: '42px',
  }
]; 